@import "../../../styles/global/vars";

.client-container {
  z-index: 3;
  background: #fff;
  margin-top: 40px;
  position: relative;
  border-radius: 2px;
  border: 1px solid $border_grey;
}

//FORM
.client-form {
  width: 100%;

  &__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    min-width: 250px;

    @media all and (max-width: 600px) {
      flex-direction: column;
      margin-bottom: 20px;
    }

    .form--inp {
      width: 50%;

      @media all and (max-width: 600px) {
        width: 100%;
        margin-bottom: 0;
      }
    }

    &-last {
      max-width: calc(50% - 10px);
      margin-right: auto;

      .form--inp {
        width: 100%;
      }

      @media all and (max-width: 600px) {
        width: 100%;
        max-width: unset;
      }
    }
  }

  &__button {
    display: flex;
    justify-content: space-between;
    width: 100%;

    a {
      max-width: 30%;
      text-decoration: none;
      font-size: 16px;
      color: #fff;
      background-color: #7d94c8;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      font-weight: 700;
      padding: 10px 20px;
      border: 1px solid transparent;
      cursor: pointer;

      &.btn-danger {
        background-color: #ff0000;

        &:hover {
          background-color: #b20e0e;
        }
      }


      &:hover {
        background: #1d446f;
      }

      img {
        margin-left: 10px;
        filter: brightness(15);
      }
    }
  }

  &__pin {
    border: 2px solid #1d446f;
    overflow: hidden;
    color: #7d94c8;
    font-weight: 700;
    width: 48%;
    margin-right: auto;
    transition: all .3s, margin-top .3s !important;
    height: 0;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;


    #kt_clipboard_1 {
      border: none;
      font-size: 16px!important;
      color: #7d94c8;
    }

    &.show {
      opacity: 1;
      height: auto;
      margin-top: 20px;
      padding: 5px 10px;
    }

    & button {
      margin-bottom: 0!important;
      padding: 5px 10px!important;
      border: none;
      cursor: pointer;

      &:hover {
        background: #1d446f!important;
      }
    }
  }
}
