.mini-creator-summary-container {
  background-color: white;
  display: flex;
  flex-direction: row;
  padding: 40px;
  @media all and (max-width: 1070px) {
    flex-wrap: wrap;
  }
}
.section-mini-creator-pdf-container {
  //width: 100%;
  @media all and (max-width: 1070px) {
    overflow: auto;
  }
}
.section-mini-creator-form {
  width: 100%;
  max-width: 40%;
  margin-left: auto;
  .form {
    align-items: flex-start;
    .form-container {
      width: 100%;
      border: 1px solid #E1E1E1;
      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 20px;

      .form--inp-title{
        padding: 10px;
        color: white;
        font-size: 16px;
        font-weight: 700;
        background-color: #7D94C8;
      }
      .form--inp-container {
        padding: 10px;
      }
      .form--inp-description {
        font-size: 12px;
        font-weight: 500;
        color: #1D446F;
      }
    }
    .title {
      text-align: left;
    }
  }

  .checkbox-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    label {
      font-size: 12px;
    }
  }

  .cb-box {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 3px;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 1px solid #1D446F;
      border-radius: 2px;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: #e1e1e1;
    }


    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    & input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    & .checkmark:after {
      left: 6px;
      top: 2px;
      width: 7px;
      height: 10px;
      border: solid #7D94C8;
      border-width: 0 1px 1px 0;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
    }
  }
}
