@import "src/styles/global/vars";

.history-container {
  .content-items {
    background: unset;
    border: none;

    .accordion-container {
      background: white;
      border: 1px solid #E1E1E1;
    }

    .content-accordion__item {
      border: none;
    }

    .content-accordion__box {
      flex: 1;
      max-width: 10%;

      p {
        word-break: break-word;
        min-height: 42px;
      }
    }

    .content-accordion-expand {
      padding-top: 40px;
    }
  }
}

.dashboard-container {
  .accordion-container {
    width: 100%;
    //overflow: auto;

    .content-accordion__item--bottom {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 0 24px 8px;
      flex-wrap: wrap;
      gap: 20px;

      .content-accordion__title {
        color: #1D446F;
        font-size: 14px;
        font-weight: bold;
        min-height: 42px;
      }
    }

    &:last-child {
      .content-accordion__item:not(:first-child) {
        border-bottom: none;
      }
    }

    p {
      font-size: 14px;
      font-weight: bold;

      a {
        color: #1d446f;
      }
    }

    .accordion__table {

      th {
        color: #1d446f;
        font-size: 14px;
        font-weight: 700;
        padding: 5px 10px;
      }

      td {
        font-size: 12px;
        padding: 5px 10px;
        font-weight: bold;

        a {
          color: #7d94c8;
          text-decoration: none;
        }
      }
    }

  }

  .content-accordion-expand {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 10px 24px 10px;
    //border-top: 1px solid #e1e1e1;
    transition: opacity .3s;
    height: auto;
  }
}

.custom-input-container {
  width: 100%;
  max-width: 1300px;
  justify-content: flex-end;
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  input {
    height: 44px;
    border: 2px solid #1d446f;
    border-radius: 0;
    color: #1d446f;
    font-weight: 700;

    //&::placeholder {
    //  color: #1d446f;
    //}
  }

  .custom-input {
    width: 100%;
    max-width: 390px;
    border: 2px solid #1d446f;
    font-size: 12px;
    padding: 12px 14px;
    color: #1d446f;
    max-height: 44px;
    background: #fff;
    flex-grow: 1;
    //margin-right: 10px;
  }

}

.accordion-icons {
  margin-left: auto;
  justify-content: flex-end;

  @media (max-width: 550px) {
    margin: auto;
    justify-content: center;
  }

  img {
    display: flex;
    align-self: center;
    cursor: pointer;
    transition: all .5s ease-in-out;

    &.show {
      transform: scaleY(-1);
    }
  }
}
