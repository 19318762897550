.header-container {
  padding: 12px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  position: relative;
  @media all and (max-width: 470px) {

    padding: 12px 20px;
  }


  .burger-icon {
    width: 30px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
      display: block;
      height: 3px;
      background: #1D446F;
    }
  }

  .menu {
    display: flex;
    list-style: none;
    z-index: 8;
    transition: .3s ease-in-out;
    @media all and (max-width: 800px) {
      flex-direction: column;
      position: absolute;
      background: #fff;
      left: -150%;
      top:65px;

      &.open {
        z-index: 8;
        background: #fff;
        border: 2px solid #E1E1E1;
        width: 100%;
        height: auto;
        display: flex;
        top: 65px;
        left: 0;
        align-items: center;
      }
    }

    > li {
      margin-right: 60px;
      border: 1px solid transparent;

      @media all and (max-width: 1200px) {
        margin-right: 40px;
      }
    }

    li {
      z-index: 1;
      padding: 10px;
      cursor: pointer;

      @media all and (max-width: 800px) {
        margin-right: 0;
      }

      &:hover {
        border: 1px solid #1D446F;
        background: rgba(239, 240, 248, 0.7);
      }
    }

    li:nth-child(2) {
      &:hover {
        .sub-menu-container {
          visibility: unset;
          height: unset;
          display: flex;
          z-index: 11!important;
        }
      }
    }

    a {
      font-size: 16px;
      text-decoration: none;
      font-weight: 700;
      display: flex;
      color: #1D446F;
      justify-content: center;

      @media all and (max-width: 800px) {
        font-size: 24px;
      }
    }

    li:last-of-type {
      display: flex;
      align-items: center;
      margin-right: 0;

      @media all and (max-width: 1200px) {
        margin-right: unset;
      }

      a {
        margin-right: 5px;
      }

      img {

        @media all and (max-width: 1200px) {
          margin-right: 5px;
        }
      }
    }

    .sub-menu {
      position: relative;
      z-index: 10;
      cursor: pointer;

      &::before {
        content: "";
        width: 100%;
        height: 100px;
        position: absolute;
      }

      &-container {
        background: #fff;
        visibility: hidden;
        border: 2px solid #E1E1E1;
        border-radius: 2px;
        position: absolute;
        padding: 30px;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        height: 240px;
        display: none;
        flex-direction: column;
        justify-content: space-between;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          width: 18px;
          height: 18px;
          background: #fff;
          border-left: 1px solid #E1E1E1;
          border-right: 1px solid transparent;
          border-top: 1px solid #E1E1E1;
          border-bottom: 1px solid transparent;

        }

        > li {
          border: 1px solid transparent;

          &:hover {
            border: 1px solid #1D446F;
            background: transparent;
          }
        }
      }
    }
  }

  #burger-icon {
    z-index: 10;
    width: 40px;
    height: 25px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .3s ease-in-out;
    cursor: pointer;
    display: none;

    @media all and (max-width: 800px) {
      display: block;
    }
  }

  #burger-icon span, span {
    display: block;
    position: absolute;
    height: 6px;
    width: 100%;
    background: #1d446f;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .3s ease-in-out;
  }

  #burger-icon span:nth-child(1) {
    top: 0px;
  }

  #burger-icon span:nth-child(2) {
    top: 12px;
  }

  #burger-icon span:nth-child(3) {
    top: 24px;
  }

  #burger-icon.open span:nth-child(1) {
    top: 12px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  #burger-icon.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  #burger-icon.open span:nth-child(3) {
    top: 12px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
}
.language-select {
  .mantine-Select-input {
    color: #1D446F;
    font-weight: 700;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    border-bottom: none;
  }
}
