.data-edit-container {
  max-width: 840px;
  z-index: 9;
  background: #fff;
  margin-top: 40px;
  padding: 25px;

  .login-form {
    align-items: flex-start;

    & >div:first-of-type {
      display: flex;
      gap: 40px;
      width: 100%;
    }
    &__inp {
      max-width: 375px;
    }
  }
  .btn-danger {
    background: #FF0000;


    &:hover {
      background: #1D446F;
    }
  }
}
.del-account {
  > p:first-of-type,
  .container {
    max-width: 570px;
  }
}
.underline-form {
  width: 100%;
  height: 1px;
  background: #E1E1E1;
  margin-bottom: 20px;
}

//SECTION ACTION READY
.action-ready {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 450px;
    text-align: center;
    margin: 0 auto;
  }
  .section-top-icon {
    width: 76px;
    padding-top: 60px;
  }
  .btn {
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    background: #7D94C8;
    padding: 14px 27px;
    color: #fff;
    text-decoration: none;
    display: flex;
    justify-content: center;
    margin-top: 40px;

    z-index: 4;

    &:hover {
      background:#1D446F;
    }

    img {
      width: 24px;
      margin-left: 10px;
      filter: brightness(15);
    }
  }
}

.client-form {
  &__button {
    button {
      max-width: 30%;
      text-decoration: none;
      font-size: 16px;
      color: #fff;
      background-color: #7d94c8;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      font-weight: 700;
      padding: 10px 20px;
      border: 1px solid transparent;
      cursor: pointer;

      &:disabled {
        filter: grayscale(0.5);
        color: #dfdfdf;
        pointer-events: none;
      }
      &.btn-danger {
        background-color: #ff0000;

        &:hover {
          background-color: #b20e0e;
        }
      }


      &:hover {
        background: #1d446f;
      }

      img {
        margin-left: 10px;
        filter: brightness(15);
      }
    }
  }
}