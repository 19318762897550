.section-login {
  background: #F6F5F5;
  padding-bottom: 120px;
  position: relative;

  @media all and (max-width: 500px) {
    padding: 20px;
  }

  .bg-image {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
  }
  .login-logo {
    margin: 0 auto;
    padding: 40px 0;
    display: block;
  }

  .login-container {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    max-width: 1280px;
    border: 1px solid #E1E1E1;
    border-radius: 2px;

    @media all and (max-width: 500px) {
      flex-direction: column-reverse;
    }

    &__form {
      max-width: 620px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
    }

    &__img {
      width: 660px;
      min-height: 480px;
      background-image: url("../../../assets/image34.png");
      background-position: center;
      background-size: cover;
      z-index: 10;

      @media all and (max-width: 500px) {
        width: 100%;
        max-height: 373px;
      }
    }
  }
}