.section-register {
  .form .btn {
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }

  .login-container__img {
    @media all and (max-width: 750px) {
      display: none;
    }
  }

  .form {
    > div {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media all and (max-width: 450px) {
        flex-direction: column;
      }
    }

    &--inp {
      margin-right: 10px;
      max-width: 180px;

      @media all and (max-width: 450px) {
        width: 100%;
        max-width: unset;
      }
    }
  }

  .checkbox-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    label {
      font-size: 12px;
    }
  }

  .cb-box {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 1px solid #1D446F;
      border-radius: 2px;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: #e1e1e1;
    }


    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    & input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    & .checkmark:after {
      left: 6px;
      top: 2px;
      width: 7px;
      height: 10px;
      border: solid #7D94C8;
      border-width: 0 1px 1px 0;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
    }
  }
}

.section-reset-password-1 {
  img {
    margin-top: 60px;
  }
  p {
    font-size: 14px;
    text-align: center;
  }
  h2 {
    margin-top: 45px;
  }
  .login-form__underline {
    margin-top: 30px;
  }
}
.section-reset-password {
  height: 100%;
  margin: 0 20px;

  h2 {
    margin-top: 55px;
  }
  .login-form__underline {
    margin-top: 65px;

    @media all and (max-width: 500px) {
      margin-top: 60px;
    }
  }

  p {
    margin-bottom: 10px;
    @media all and (max-width: 500px) {
      margin-bottom: 10px;
    }
  }
}