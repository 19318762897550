@import "src/styles/global/vars";

.visualization {
  opacity: 1;
  display: flex;
  width: 100%;
  min-height: 600px;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all .3s ease-in-out;
  margin-top: 24px;
  margin-bottom: 24px;

  &__container {
    overflow: auto;
    /* width */
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;

    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #fff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #7D94C8;
      border-radius: 60px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #1D446F;
    }
  }

  &-img {
    &.translateX {
      transform: translateX(-50%);
    }
  }

  .close-btn {
    position: absolute;
    top: -18px;
    right: 5px;
    cursor: pointer;
  }

  &-box {
    width: 90%;
    height: 90%;
    min-width: 740px;
    position: relative;
    height: 540px;
    margin: auto;
    overflow: hidden;

    .visualization-img--background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
    }
  }

  &-buttons {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      background: $light_blue;
      border-radius: 2px;
      cursor: pointer;

      &--input {
        opacity: 0;
        position: absolute;
        z-index: -1;
      }

      &--label {
        cursor: pointer;
      }

      &:hover {
        background: $dark_blue;
      }

      img {
        filter: brightness(15);
      }
    }
  }

  img {
    width: 100%;
  }
}

.fullscreen-enabled {
  display: flex;
  justify-content: center;
}
