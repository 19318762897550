$transition: 0.25s all ease-in-out;
$border_grey:#E1E1E1;
$border_blue:#7D94C8;
$border_light-blue:#CBD4E9;
$dark_blue: #1D446F;
$light_blue:#7D94C8;
$hover-color: $dark_blue;
$color-red: #BB4430;
$color_light-red: rgb(255,0,0);
$BTN-secondary: #EAEEF6;
$color-green: #00B031;