.footer-container {
  //max-width: 1440px;
  margin: 0 auto;


  .bg-image {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
footer {
  padding: 50px 80px;
  background: #E1E1E1;
  position: relative;
  font-size: 12px;

  @media all and (max-width: 500px) {
    padding: 10px 20px;
  }

  .footer-logo {
    margin-bottom: 40px;
  }

  .footer-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;


    &__item {
      padding-left: 20px;
      border-left: 1px solid #fff;
      width: 25%;
      margin-bottom: 50px;
      min-width: 300px;

      div:nth-child(2) {
        margin-top: 15px;
      }
    }
  }
  .footer-bottom {
    margin-top: 60px;

    a {
      color: #000;
    }
  }
}