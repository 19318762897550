@import "src/styles/global/vars";

.dashboard {
  position: relative;
  background: #F6F5F5;
  padding: 0 80px 80px;

  .main-button {
    width: 100%;
    background: $BTN-secondary;
    padding: 20px;
    display: flex;
    justify-content: center;
    max-width: 560px;
    border-radius: 2px;
    border: 1px solid $border_blue;
    color: $dark_blue;
    text-decoration: none;
    font-weight: bold;
    margin-top: 20px;

    &--accept {
      background: #00B031;
      color: white;
      border: 1px solid white;
      transition: all .3s ease-in-out;
      cursor: pointer;
      img {
        filter: brightness(10)!important;
      }
      &:hover {
        background: #00B031 !important;
        color: white!important;
        border: 1px solid white!important;
        opacity: 0.8;
      }
    }


    img {
      margin-left: 10px;
    }

    &:hover {
      background: #fff;
    }
  }

  @media all and (max-width: 470px) {
    padding: 0 20px 20px;
  }

  & p.dashboard-description {
    max-width: 820px;
  }

  & > p:first-of-type {
    font-size: 14px;
  }

  .bg-image {
    position: absolute;
    z-index: 2;
    right: 0;
    height: 600px;
  }

  &-title {
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 500;
    padding: 60px 0 40px;
  }

  &-container {
    display: flex;

    @media all and (max-width: 1100px) {
      flex-direction: column;
    }
  }
  .content-form {
    position: relative;
    &--loader {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(125, 148, 200, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-content {
    display: flex;
    //flex-wrap: wrap;
    gap: 40px;
    width: 100%;

    @media (max-width: 950px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &-container-main {
    display: flex;
    //justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    max-width: 1320px;
    margin-bottom: 40px;
    //margin: 0 auto;

    @media (max-width: 1400px) {
      gap: 20px;
    }

    &__item {
      padding: 25px;
      max-width: 400px;
      min-width: 300px;
      flex-basis: 0;
      flex-grow: 1;
      border-radius: 2px;
      max-height: 459px;
      border: 1px solid #e1e1e1;
      background: #fff;
      z-index: 3;
      margin-top: 20px;

      > p {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 40px;
      }
    }
  }

  &-item {
    border: 2px solid #1D446F;
    border-radius: 2px;
    margin-bottom: 8px;
    position: relative;

    &:hover {
      img {
        opacity: 0.9;
        filter: brightness(.5);
      }

      p {
        color: #fff;
      }

      .dashboard-item__icon {
        filter: brightness(15);
      }
    }

    img {
      transition: .3s;
      position: absolute;
      opacity: 0.3;

      &:first-of-type {
        width:  100%;
        height: 100%;
      }

      &.dashboard-item__icon {
        transition: none;
        opacity: 1;
      }
    }

    p {
      position: absolute;
      color: #1D446F;
      font-size: 18px;
      font-weight: bold;
      bottom: 16px;
      left: 16px;
    }

    &__icon {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #1D446F;
      font-size: 30px;
      font-weight: bold;
      transition: none;
    }
  }

  &-item__big {
    max-width: 352px;
    height: 170px;
  }

  &-item__small {
    max-width: 172px;
    height: 170px;
    flex-grow: 1;
  }

  .small-item__container {
    display: flex;
    gap: 5px;
  }
}
.dashboard-container {
  &--big {
    gap: 40px;
  }

  .dashboard-content__extra {
    @media (max-width: 1150px) {
      width: 100%;
    }
  }

  .content-items,
  .dashboard-content__extra {

    &__big {
      max-width: unset!important;
      width: 100%;
    }

    &__small {
      gap: 0;
    }

    &__medium {
      max-width: 845px;


      .content-item3-accessories {
        flex-basis: 100%;
        max-width: 250px;
      }

      .content-item--extra {
        overflow: hidden;

        .top {
          display: flex;
          flex-direction: column;
          margin-top: auto;
          overflow: hidden;
          transition: all .3s;
        }

        .bottom {
          display: none;
          position: relative;
          transition: all .3s;
          overflow: hidden;

          label {
            color: #1d446f;
            font-weight: 600;
            font-size: 14px;

            input,
            select {
              border: 2px solid #1d446f;
              font-size: 12px;
              padding: 12px 14px;
              color: #7d94c8;
              opacity: .6;
              font-weight: 700;
              width: 100%;
              background: #fff;
            }
          }

          a {
            position: relative;
            display: flex;
            justify-content: center;
            color: #fff;
            background: #7d94c8;
            padding: 15px 0;
            border-radius: 2px;
            text-align: center;
            font-weight: 700;
            margin-top: 10px;
            text-decoration: none;
          }
        }
      }
    }
  }

  .accordion-container {
    width: 100%;
    //overflow: auto;

    //&:last-child {
    //  .content-accordion__item:not(:first-child) {
    //    border-bottom: none;
    //  }
    //}

    &:first-of-type {
      .content-accordion__item {
        border-top: none;
        border-bottom: none;
      }
    }
    &:last-of-type {
      .content-accordion__item {
        border-bottom: none;
      }
    }

    p {
      font-size: 14px;
      font-weight: bold;

      a {
        color: #1d446f;
      }
    }

    .accordion__table {

      th {
        color: #1d446f;
        font-size: 14px;
        font-weight: 700;
        padding: 5px 10px;
      }

      td {
        font-size: 12px;
        padding: 5px 10px;
        font-weight: bold;

        a {
          color: #7d94c8;
          text-decoration: none;
        }
      }
    }
  }

  .content-accordion__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 8px 24px;
    flex-wrap: wrap;
    gap: 20px;
    //background: $border_grey;
    border-bottom: 1px solid $border_grey;
    border-top: 1px solid $border_grey;
    //max-height: 50px sprawdzić czy działa;F



    & > h3 {
      font-size: 18px;
    }

    &>div {

      @media (max-width: 1250px) {
        width: 20%;
      }
      @media (max-width: 750px) {
        width: 25%;
      }
      @media (max-width: 550px) {
        width: 45%;
      }
    }

    .accordion-icons {
      margin-left: auto;
      justify-content: flex-end;

      @media (max-width: 550px) {
        margin: auto;
        justify-content: center;
      }

      img {
        display: flex;
        align-self: center;
        cursor: pointer;


        &.show {
          transform: rotate(180deg);
        }
      }

      .accordion-icons__eye {
        background-color: $BTN-secondary;
        padding: 5px 10px;
        border: 1px solid $border_blue;
        border-radius: 2px;

        &:hover {
          background-color: #fff;
          filter: opacity(.7);
        }
      }
    }


    .content-accordion__price {
      font-size: 14px;
      font-weight: bold;

      span {
        text-transform: lowercase;
      }
    }

    .content-accordion-input-box {
      position: relative;

      button {
        position: absolute;
        border: none;
        background: none;
        cursor: pointer;
        width: 22px;
        height: 22px;

        img {
          width: 100%;
          height: 100%;
        }

        &:first-of-type {
          top: -3px;
          right: 25px;
        }

        &:last-of-type {
          bottom: -3px;
          right: 25px;
        }
      }
    }

    .content-accordion__input {
      border: 2px solid #1d446f;
      font-size: 12px;
      padding: 12px 14px;
      color: $dark_blue;
      font-weight: bold;
      max-width: 100px;
      max-height: 32px;
      margin-right: 25px;
    }
  }

  .content-item {

    @media all and (max-width: 470px) {
      width: 224px;
      height: 197px;
    }


    &__icon {
      position: absolute;
      right: 5px;
      top: 5px;
      max-width: 32px;
      max-height: 32px;
      display: flex;
      justify-content: center;
      color: #fff;
      background: $light_blue;
      border-radius: 2px;
      padding: 5px;

      img {
        margin-left: 0 !important;
      }
    }



    img {
      filter: brightness(15);
      margin-left: 10px;
    }

  }

  .content-item1,
  .content-item2 {
    &::before {
      //background-image: url('../../../src/assets/historia-zam.png');
    }
  }

  .content-item3,
  .content-item4 {
    &::before {
      //background-image: url('../../../src/assets/nowa-oferta.png');
    }
  }
}

.dashboard-container {
  padding-top: 60px;
  transition: .3s ease-in-out;

  .dashboard-content__left {
    z-index: 4;
    flex-grow: 2;

    .content-title {
      font-size: 28px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    .content-description {
      max-width: 840px;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 40px;
    }
  }

  .dashboard-content__extra {
    @media (max-width: 1150px) {
      width: 100%;
    }
  }
  .content-items {
    flex-grow: 1;
    gap: 24px;
  }

  .content-items,
  .dashboard-content__extra {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: #fff;

    max-width: 624px;
    border-radius: 2px;
    border: 1px solid $border_grey;

    &__small {
      gap: 0;
    }

    &__medium {
      max-width: 845px;

      .content-item {
        max-width: 274px;
        min-width: 200px;
        width: unset;
        margin: unset;
        flex-grow: 1;
        flex-basis: 0;

        &__drive {
          position: relative;
          max-width: 249px;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(255, 255, 255, 1);
          }
        }
      }
      .content-item3-accessories {
        flex-basis: 100%;
        max-width: 250px;
      }
    }

    .items-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      //gap: 20px;
      height: 0;
      opacity: 0;

      &.show {
        height: unset;
        opacity: 1;
        width: 100%;
      }
    }
  }

  .accordion-container {
    width: 100%;

    &:last-child {
      .content-accordion__item {
        //border-bottom: none;
      }
    }

    p {
      font-size: 14px;
    }
  }

  .content-accordion__item {
    .accordion-icons {
      img {
        display: flex;
        align-self: center;
        cursor: pointer;


        &.show {
          transform: rotate(180deg);
        }
      }

      .accordion-icons__eye {
        background-color: $BTN-secondary;
        padding: 5px 10px;
        border: 1px solid $border_blue;
        border-radius: 2px;

        &:hover {
          background-color: #fff;
          filter: opacity(.7);
        }
      }
    }

    .content-accordion__title {
      color: $dark-blue;
      font-size: 14px;
      font-weight: bold;
      flex-grow: 2;
    }

    .content-accordion__price {
      font-size: 14px;
      font-weight: bold;

      span {
        text-transform: lowercase;
      }
    }

    .content-accordion-input-box {
      position: relative;

      button {
        position: absolute;
        border: none;
        background: none;
        cursor: pointer;
        width: 22px;
        height: 22px;
        img {
          width: 100%;
          height: 100%;
        }

        &:first-of-type {
          top: -3px;
          right: 25px;
        }
        &:last-of-type {
          bottom: -3px;
          right: 25px;
        }
      }
    }

    .content-accordion__input {
      border: 2px solid #1d446f;
      font-size: 12px;
      padding: 12px 14px;
      color: $dark_blue;
      font-weight: bold;
      max-width: 100px;
      max-height: 32px;
      margin-right: 25px;
    }
  }

  .content-accordion-expand {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    overflow: hidden;
    height: 0;
    opacity: 0;
    //border-bottom: 1px solid #e1e1e1;
    transition: none;

    &.show {
      height: unset;
      opacity: 1;
      display: flex;
      padding: 10px 24px 10px;
      margin-top: 10px;
      transition: none;
    }

    .accordion-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 190px;
      height: 221px;
      border: 1px solid $dark-blue;
      padding: 16px;

      img {
        max-height: 90px;
      }

      &__title {
        margin-top: 10px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
      }

      a {
        width: 100%;
        max-height: 48px;
        align-items: center;
        margin-top: unset;

        img {
          filter: brightness(15);
          margin-left: 10px;
        }
      }
    }
  }

  .dashboard-content__extra {
    align-self: flex-start;
    //flex-grow: 1;
  }

  .dashboard-content__right {
    max-width: 400px;
    width: 100%;
    flex-grow: 1;
    border-radius: 2px;
    align-self: flex-start;
    justify-content: center;
    margin-left: auto;
    z-index: 2;

    @media (max-width: 850px) {
      align-self: unset;
      margin-left: unset;
    }
  }

  .content-item {
    width: 274px;
    min-height: 247px;
    border-radius: 2px;
    border: 2px solid $dark_blue;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 17px;
    margin: auto;

    @media all and (max-width: 470px) {
      width: 224px;
      height: 197px;
    }

    &::before {
      content: "";
      background-size: cover;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.3;
    }

    &__icon {
      position: absolute;
      right: 5px;
      top: 5px;
      max-width: 32px;
      max-height: 32px;
      display: flex;
      justify-content: center;
      color: #fff;
      background: $light_blue;
      border-radius: 2px;
      padding: 5px;

      img {
        margin-left: 0!important;
      }
    }

    &__title {
      margin-top: auto;
      text-decoration: none;
      position: relative;
      color: $dark_blue;
      font-size: 18px!important;
      font-weight: bold;

      @media all and (max-width: 470px) {
        font-size: 16px;
      }
    }

    &__link {
      position: relative;
      display: flex;
      justify-content: center;
      color: #fff;
      background: $light_blue;
      padding: 15px 0;
      border-radius: 2px;
      text-align: center;
      font-weight: bold;
      margin-top: 10px;
      text-decoration: none;
      outline: none;
      border: none;
      cursor: pointer;

      &--cart {
        &::before {
          content: unset!important;
        }
        &::after {
          content: unset!important;
        }
      }


      //&::before {
      //  content: "Wybierz";
      //}

      &::after {
        content: "";
        background: url("../../../src/assets/icons/add.png");
        background-size: cover;
        width: 24px;
        height: 24px;
        display: block;
        margin-left: 5px;
        filter: brightness(15);
      }
      &.loader {
        &::before {
          display: none;
        }

        &::after {
          display: none;
        }
      }
      &:hover {
        background: $hover-color;
      }

      &.chosen {
        background: $color_green;

        &::after {
          background: url("../../../src/assets/icons/done.png");
        }
      }

      &.delete {
        background: $color_light-red;

        &::before {
          content: "Usuń";
        }

        &::after {
          background: url("../../../src/assets/icons/delete.png");
        }
      }
    }

    img {
      filter: brightness(15);
      margin-left: 10px;
    }

  }

  .content-accessory {
    &::before {
      background-image: url('../../../src/assets/historia-zam.png');
    }
  }

  .content-item3,
  .content-item4 {
    &::before {
      //background-image: url('../../../src/assets/nowa-oferta.png');
      //background-image: url('../../../src/assets/nowa-oferta.png');
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgb(241, 241, 241);
      filter: opacity(1);
    }
  }
}

//DASHBOARD 2.1
.dashboard-container-step {
  & > div:first-of-type {
    max-width: 842px !important;
    //flex-wrap: nowrap!important;
  }

  .small-box-item {
    width: 248px !important;
  }
}

//DASHBOARD 3

.content-form {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background: #fff;
  padding: 24px;
  width: 100%;
  max-width: 624px;
  border-radius: 2px;
  border: 1px solid $border_grey;

  &__medium {
    max-width: 845px;

    @media all and (max-width: 900px) {
      flex-direction: column-reverse;
    }
  }

  &__small {
    max-width: 510px;

    .form--inp {
      width: 100% !important;
    }
  }

  .content-bg {
    width: 50%;
    align-self: flex-start
  }

  .form-bg {
    width: 0;
    height: 0;
    opacity: 0;

    &.show {
      width: 50%;
      height: unset;
      opacity: 1;

      img {
        width: 100%;
      }

      @media all and (max-width: 900px) {

        img {
          margin: auto;
        }
        width: 100%;
      }
    }
  }

  .dashboard-form {
    width: 0;
    height: 0;
    opacity: 0;

    &.show {
      width: 50%;
      height: unset;
      opacity: 1;

      @media all and (max-width: 900px) {
        width: 100%;
      }
    }
  }
}

//SUMMARY BOX
.basket-container {
  .summary-box__item {
    border-bottom: none !important;

    &.summary-box__last {
      border-bottom: 1px solid $dark_blue !important;
    }
  }
}

.content-accordion-input-box {
  position: relative;

  button {
    position: absolute;
    border: none;
    background: none;
    cursor: pointer;
    width: 22px;

    &:hover {
      &:first-of-type {
        &::after {
          border-color: transparent transparent #7d94c8 transparent;
        }
      }
      &:last-of-type {
        &::after {
          border-color: #7d94c8 transparent transparent transparent;
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
    }

    &:first-of-type {
      top: 8px;
      right: 0;
    }
    &:last-of-type {
      bottom: 8px;
      right: 0;
    }
  }
}

.content-accordion__input {
  border: 2px solid #1d446f;
  border-radius: 2px;
  font-size: 12px;
  padding: 12px 14px;
  color: $dark_blue;
  font-weight: bold;
  max-width: 85px;
  max-height: 32px;
}

.accessories-container {
  display: flex;
  flex-direction: column;

  p {
    font-size: 12px!important;
    color: #1d446f!important;
    font-weight: 700!important;
    white-space: nowrap!important;
    align-self: flex-end!important;
    text-align: left!important;
  }
}
.accessories-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.mantine-1ezdau6 {
  cursor: pointer;
}
.mantine-1ezdau6:not(:disabled):hover {
  background-color: transparent;
}
.react-pdf__Page__canvas {
  margin: auto;
}
