@import "src/styles/global/vars";

.summary-finished {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 620px;
    text-align: center;
    margin: 0 auto;

    .section-top-icon {
      width: 50px;
      padding-top: 60px;
    }
  }

  .summary-finished-title {
    font-weight: normal;
    margin-top: 45px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
}

.order-view {
  .content-accordion-expand {
    &.show {
      width: 50%;

      @media (max-width: 1100px) {
        width: 100%;
      }
    }
  }

  .order-view-box-container {
    max-width: 845px;
    margin-bottom: 40px;
    display: none;

    &.show {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 10px;
    }

    .order-view-box {
      max-width: 400px;
      padding: 20px;
      border-radius: 2px;
      background: #fff;
      border: 1px solid $border_grey;
      flex-grow: 1;

      @media (max-width: 800px) {
        max-width: unset;
      }

      h3 {
        font-size: 18px;
      }
    }

    .view-box-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $dark_blue;
      padding: 10px 0;

      p:first-of-type {
        font-size: 12px;
        font-weight: bold;
        color: $dark_blue;
      }
      p:last-of-type {
        font-size: 24px;
        font-weight: bold;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
  .comment-box {
    margin-top: 15px;

    textarea {
      resize: none;
      border: 2px solid #1d446f;
      font-size: 12px;
      font-weight: 700;
      padding: 12px 14px;
      color: #1D446F;
    }
  }
  .search-box,
  .comment-box {
    display: none;
    flex-direction: column;
    padding: 20px;
    border-radius: 2px;
    background: #fff;
    max-width: 845px;
    border: 1px solid $border_grey;
    margin-bottom: 15px;

    &.show {
      display: flex;
    }

    h3 {
      font-size: 18px;
      margin-bottom: 18px;
    }

    .search-box-inputs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
      gap: 20px;
      flex-wrap: wrap;
      width: 100%;

      > div {
        display: flex;
        margin-top: auto;

        &:first-of-type {
          flex-grow: 1;
        }

        &:last-of-type {
          justify-content: flex-end;

          @media all and (max-width: 1260px) {
            width: 100%;
            justify-content: unset;
          }
        }

        > div {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          margin-top: auto;
          width: 100%;

          &:first-of-type {
            margin-right: 20px;
          }

          label {
            font-size: 12px;
            font-weight: bold;
            color: $dark_blue;
          }
          .input {
            input {
              font-family: 'Montserrat', sans-serif;
              width: 100%;
              max-width: unset!important;
              border-radius: 0;
              height: 100%;
              border: 2px solid #1d446f;
              font-size: 12px;
              font-weight: 700;
              padding: 12px 14px!important;
              color: #1D446F;
              min-height: 44px;
              background: #fff;
              flex-grow: 1;
              &::placeholder {
                color: rgba(125, 148, 200, 0.9)!important;
                opacity: 0.9!important;
              }

              &.input-long {
                max-width: unset;
              }
            }
          }
          input {
            width: 100%;
            max-width: 290px;
            border: 2px solid #1d446f;
            font-size: 12px;
            padding: 12px 14px;
            color: #1D446F;
            font-weight: 700;
            max-height: 44px;
            background: #fff;
            flex-grow: 1;

            &.input-long {
              max-width: unset;
            }
          }
        }

        a {
          border: 1px solid $dark_blue;
          border-radius: 2px;
          color: $dark_blue;
          text-decoration: none;
          text-align: center;
          height: 44px;
          min-width: 155px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $BTN-secondary;
          font-weight: bold;

          &:hover {
            background: #fff;
          }

          @media all and (max-width: 1260px) {
            width: 100%;
          }

        }
      }
    }
  }
}

.order-container {
  z-index: 3;
  background: #fff;
  margin-top: 40px;
  position: relative;
  border-radius: 2px;
  border: 1px solid $border_grey;
  max-width: 1200px;
}

.order-summary-container {
  padding: 24px;
  border: 1px solid $dark-blue;
  border-radius: 2px;
  max-width: 620px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  z-index: 6;

  h3 {
    font-size: 18px;
    text-align: left;
    margin-bottom: 25px;
  }

  .order-summary-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid $dark_blue;
    font-weight: bold;

    @media all and (max-width: 390px) {
      flex-direction: column;
    }

    &__right {
      font-size: 18px;
      text-align: right;
    }

    &__left {
      color: $dark_blue;
      font-size: 12px;
      text-align: left;
    }
  }

  .summary-box__buttons {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 10px;

    a {
      display: flex;
      text-decoration: none;
      gap: 12px;

      &.button-1 {
        color: $dark_blue;
        font-weight: bold;
        padding: 10px;
        background: $BTN-secondary;
        border: 1px solid $border_blue;
        border-radius: 2px;
        flex-grow: 1;
        max-width: 100%;
        justify-content: center;


        &:hover {
          background-color: #fff;
          border: 1px solid $light_blue;
        }
      }

      &.button-2 {

        color: #fff;
        background-color: $light_blue;
        display: flex;
        justify-content: center;
        flex-grow: 2;
        font-weight: bold;
        padding: 10px;
        max-width: 340px;
        border: 1px solid transparent;
        @media all and (max-width: 630px) {
          width: 100%;
          max-width: unset;
        }

        img {
          margin-left: 10px;
          filter: brightness(15);
        }

        &:hover {
          background: $dark_blue;
        }
      }
    }
  }
}

.content-accordion__button {
  display: flex;
  margin-left: 20px;
  gap: 10px;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  a,button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $dark_blue;
    width: 44px;
    height: 32px;
    background: $BTN-secondary;


    &:hover {
      background: #fff;
    }
  }
}
