@import "./vars";

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Montserrat', sans-serif;
  height: 100vh;

  .overlay {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000;

    &.hovered {
      z-index: 5;
      opacity: .3;

    }
  }

}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.gap-40 {
  gap: 40px;
}

.gap-24 {
  gap: 24px;
}

.p-24 {
  padding: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}

//TABLE
.custom-table {
  overflow-x: auto;

  table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    font-size: 14px;

    th {
      font-size: 14px;
      font-weight: 400;
      color: $dark_blue;
    }

    tr, th {
      border-bottom: 1px solid $border_grey;
    }

    tr {
      &:last-of-type {
        border-bottom: none;
      }
    }

    th, td {
      text-align: left;
    }

    th {
      padding: 15px 24px;
    }

    th:last-of-type {
      text-align: right;
      padding-right: 25px;
    }

    td {
      color: $dark-blue;
      font-weight: bold;
      padding: 9px 24px;
    }

    .table-icons {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 10px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $BTN-secondary;
        padding: 3px 10px;
        border: 1px solid $border_blue;
        border-radius: 2px;
        cursor: pointer;
        min-width: 46px;

        &:hover {
          background-color: #fff;
          filter: opacity(.7);
        }

      }
    }

    .status-dot {
      border-radius: 50%;
      background: $border_grey;
      width: 12px;
      height: 12px;
      margin: 0 auto;
      transform: translateX(-100%);

      &-active {
        background: $color-green;
      }

      &-deactive {
        background: $color-red;
      }
    }
  }

  &__clients {
    table {
      th {
        font-weight: 700;
        color: #000;
      }
    }

  }
}

//FORM

::placeholder {
  color: rgba(125, 148, 200, 0.9)!important;
  font-weight: 700;
  font-size: 12px;
  opacity: 1;
  //text-align: right;
}

//BUTTONS
.button-2 {
  color: #fff;
  background-color: $light_blue;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  font-weight: bold;
  padding: 10px 50px;
  max-width: 187px;
  white-space: nowrap;
  border: 1px solid transparent;
  text-decoration: none;
  z-index: 2;
  cursor: pointer;

  @media (max-width: 750px) {
    padding: 10px 25px;
  }

  img {
    margin-left: 10px;
    filter: brightness(15);
  }

  &:hover {
    background: $dark_blue;
  }
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
