@import "../../styles/global/vars";

.basket-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 12px;
  width: 100%;
  //border-top: 1px solid #cbd4e9;

  .basket-left {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    width: 100%;

    .basket-box {
      padding-bottom: 8px;
      border-bottom: 1px solid $border_light-blue;
      margin-top: -10px;

      &:last-of-type {
        border-bottom: none;
      }

      .basket-item {
        display: flex;
        align-items: center;
        margin-top: 15px;
        font-weight: bold;
        position: relative;

        @media (max-width: 550px) {
          flex-direction: column;
        }

        & > img {
          width: 20px;
          height: 20px;
          margin-left: 10px;
          display: block;
          cursor: pointer;
        }

        .tooltip {
          position: relative;

          cursor: pointer;
          margin-left: 5px;

          &:hover {
            .tooltip__txt {
              opacity: 1;
              visibility: visible;
            }
          }

          &__txt {

            opacity: 0;
            visibility: hidden;
            position: absolute;
            max-width: 260px;
            min-width: 200px;
            display: block;
            font-size: 14px;
            padding: 20px;
            font-weight: 400;
            background: #fff;
            z-index: 10;
            border: 1px solid #c4c4c4;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

            &::before {
              content: "";
              width: 18px;
              height: 18px;
              position: absolute;
              background: #fff;
              bottom: -10px;
              left: 50%;
              border-right: 1px solid #c4c4c4;
              border-bottom: 1px solid #c4c4c4;
              transform: translateX(-50%) rotate(45deg);
              box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
            }
          }
        }

        &__left {
          font-size: 12px;
          color: $dark-blue;
          align-self: center;

          &--round {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: $dark-blue;
            color: white;
            margin-right: 5px;
          }
        }

        &__right {
          font-size: 14px;
          align-self: flex-end;
          text-align: right;
          margin-left: auto;
          @media (max-width: 550px) {
            margin-right: auto;
          }

          select {
            width: 94px;
          }
          img {
            cursor: pointer;
            width: 20px;
          }
          button {
            position: absolute;
            border: none;
            background: none;
            cursor: pointer;
            width: 22px;
            &:first-of-type {
              top: 8px;
              right: 0;
            }
            &:last-of-type {
              bottom: 8px;
              right: 0;
            }
          }
          input,
          select {
            max-width: 160px;
            border: 2px solid #1d446f;
            font-size: 12px;
            padding: 12px 14px;
            color: rgba(125, 148, 200, 0.9)!important;
            max-height: 44px;
            background: #fff;

            &:focus-visible {
              outline: none;
            }
          }
        }
      }
    }
  }

  .basket-right {
    width: 55%;
    flex-direction: column;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @media all and (max-width: 1395px) {
      width: 100%;
    }

    .details {
      &__item {
        margin: 24px 0;

        strong {
          color: #1d446f;
          font-size: 14px;
          font-weight: 700;
        }

        p {
          font-size: 12px;
          font-weight: 700;
        }
      }
    }

    .details {
      @media all and (max-width: 1395px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      @media all and (max-width: 550px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__item {
        @media all and (max-width: 550px) {
          text-align: center;
        }
      }
    }

    .buttons-box {
      @media all and (max-width: 1395px) {
        max-width: unset;
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;
      }

      @media all and (max-width: 550px) {
        display: flex;
        flex-direction: column;
      }

      button {
        cursor: pointer;
        max-height: 49px;
        padding: 10px 20px;

        img {
          margin-left: 10px;
        }
      }
    }
  }
}
