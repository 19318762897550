.section-login-form {
  margin: 20px;
  max-width: 400px;
  flex-grow: 1;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &--inp {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    position: relative;

    input,
    textarea,
    select {
      border: 2px solid #1D446F;
      font-size: 12px;
      padding: 12px 14px;
      color: #1D446F;
      opacity: .8;
      font-weight: 700;
      min-height: 46px;
      font-family: 'Montserrat', sans-serif!important;
      &:focus-visible {
        outline: none;
      }
      &:disabled {
        background-color: #f1f3f5;
        cursor: not-allowed;
        opacity: .7;
        &::placeholder {
          color: #5a5a5b;
        }
      }
      &::placeholder {
        color: rgba(38, 71, 116, 0.71) !important;
      }
    }

    select {
      background: #fff;
    }

    .loader-container {
      right: 25px;
      top: 32px;
      position: absolute;
    }

    img {
      position: absolute;
      top: 30px;
      right: 10px;
      cursor: pointer;
      opacity: .8;

      &.input-icon {
        right: 25px;
        top: 29px;
      }

      &:hover {
        opacity: 1;
      }
    }

    label {
      color: #1D446F;
      font-weight: 700;
      font-size: 12px;
    }
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 40px;
    text-align: center;
    max-width: 187px;
  }

  .btn {
    margin-left: auto;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    background: #7D94C8;
    padding: 10px 27px;
    color: #fff;
    text-decoration: none;
    display: flex;
    border: none;
    cursor: pointer;

    &:disabled {
      filter: grayscale(.5);
      color: #dfdfdf;

      pointer-events: none;

      img {
        opacity: .7;
      }
    }

    img {
      margin-left: 10px;
      filter: brightness(0) invert(1);
    }
  }

  &__underline {
    width: 100%;
    height: 1px;
    background: #E1E1E1;
    margin-bottom: 20px;
  }

  p {
    font-size: 12px;
  }

  a {
    color: #7D94C8;
    font-size: 12px;
  }
}
