@import "src/styles/global/vars";

.search-box {
  max-width: 845px;
  margin-bottom: 25px;
  border-radius: 2px;
  border: 1px solid #e1e1e1;

  &-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-checkbox {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      margin: 0;
      margin-top: 4px;
      font-size: 12px;
      font-weight: bold;
      color: #1D446F;
    }

    input {
      border: 1px solid black !important;
    }
  }

  input {
    font-weight: 700;
    width: 100%;
    padding: 10px;
    border: 1px solid transparent;
    font-size: 12px;
    color: #1D446F;

    &:focus-visible {
      outline: none;
      border: 1px solid #7d94c8;
      transition: none;
    }
  }
}

.summary-container {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-width: 300px;
  z-index: 7;

  &--mini {
    max-width: unset;
    padding: 40px 80px;
  }

  .summary {
    height: 0;
    opacity: 0;


    &.show {
      height: auto;
      opacity: 1;
    }
  }

  .summary-box__top {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 24px 0 0 0;

    .underline {
      border-bottom: 1px solid $dark_blue;
      flex-grow: 1;
      max-width: 160px;
      margin-bottom: 12px;
    }


    & p:not(.active-step) {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      background-color: rgba(0, 0, 0, 0.2);
      margin-top: 4px;
    }


    p.active-step {
      background-color: $dark_blue;
      color: #fff;
      border-radius: 18px;
      padding: 4px 12px;
    }

    p.done-step {
      color: #fff;
      border-radius: 18px;
      padding: 4px 12px;
      font-size: 17px;
      width: 24px;
      background-color: #7D94C8;

      img {
        width: 24px;
        filter: brightness(15);
        max-width: unset !important;
      }
    }
  }

  .summary-box {
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 2px;
    border: 1px solid $dark_blue;
    position: relative;
    background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(32px);

    @media (max-width: 400px) {
      padding: 10px;
    }

    &__title {
      max-width: 152px;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    &__visualization {
      position: relative;

      & .expand-icon {
        background: rgba(255, 255, 255, .6);
        position: absolute;
        top: 25px;
        right: 25px;
        z-index: 10000;
      }
    }
    &__img {
      cursor: pointer;
      position: relative;
      max-width: 350px;
      height: 250px;
      width: 100%;
      overflow: hidden;

      .visualization-img--background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
      }

    }

    &__item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #CBD4E9;
      padding-bottom: 10px;
      margin-top: 20px;
      transition: all .3s ease-in-out;

      .summary-box__accessories,
      .summary-box__price {
        display: flex;
        //flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        max-width: 55%;
        width: 100%;

        p {
          width: 100%;
          text-align: left;
          white-space: normal !important;
          align-self: center !important;
          overflow-wrap: break-word;
          min-width: 35px;
        }

        &--txt {
          white-space: nowrap !important;
          font-size: 14px;
          color: #000;
          font-weight: bold;
          text-align: right
        }

        > button {
          border: 1px solid #CBD4E9;
          border-radius: 50%;
          background: #7d94c8;
          color: #fff;
          font-size: 10px;
          width: 25px;
          aspect-ratio: 1/1;
          cursor: pointer;

          &:hover {
            background: red;
            border-color: #fff;
          }
        }
      }

      .summary-box__price {
        p {
          white-space: nowrap !important;
        }
      }

      &.summary-box__last {
        border-bottom: 1px solid $dark_blue;
      }

      & > p:nth-child(1) {
        font-size: 12px;
        color: $dark_blue;
        font-weight: bold;
        white-space: nowrap;
        align-self: flex-end;
      }

      & > p:nth-child(2) {
        font-size: 14px;
        color: #000;
        font-weight: bold;
        text-align: right;
        max-width: 70%;
        word-break: break-word;
      }

      & > div:nth-child(1) {
        p {
          font-size: 12px;
          color: $dark_blue;
          font-weight: bold;
          white-space: nowrap;
          align-self: flex-end;
        }
      }

      & > div:nth-child(2) {
        p {
          font-size: 14px;
          color: #000;
          font-weight: bold;
          text-align: right;
        }
      }
    }

    &__buttons {
      display: flex;
      margin-top: 20px;

      a {
        text-decoration: none;

        &.button-1 {
          color: $light_blue;
          font-weight: bold;
          padding: 10px;
          border: 1px solid transparent;
          margin-right: 10px;

          &:hover {
            background-color: #fff;
            border: 1px solid $light_blue;
          }
        }

        &.button-2 {
          color: #fff;
          background-color: $light_blue;
          display: flex;
          justify-content: center;
          flex-grow: 1;
          font-weight: bold;
          padding: 10px 50px;
          max-width: unset;
          white-space: nowrap;
          border: 1px solid transparent;
          transition: all .3s ease-in-out;

          &.disabled {
            pointer-events: none;
            filter: grayscale(.7);
          }

          @media (max-width: 750px) {
            padding: 10px 25px;
          }

          img {
            margin-left: 10px;
            filter: brightness(15);
          }

          &:hover {
            background: $dark_blue;
          }
        }
      }
    }

    &__warning {
      margin-top: 10px;

      p {
        color: red;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .item-price {
      font-size: 28px !important;
      font-weight: bold;

      &__small {
        font-size: 12px !important;
      }
    }

    .extra-accessory {
      background: #fff;
      border: 2px solid #1d446f;
      border-radius: 2px;
      padding: 7px;
      margin-bottom: 10px;

      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        strong {
          font-size: 12px !important;
          margin-right: 10px;
        }

        p {
          text-align: right !important;
          margin: 0;
        }
      }
    }

    .social-icon {

      width: 100%;
      cursor: pointer;
      padding: 20px 0 0;

      iframe {
        width: 100%;
      }

      img {
        transition: all .3s ease-in-out;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }

    &--mini {
      max-width: unset;

      .summary-box__title {
        max-width: unset;
      }
    }
  }

  .summary-description {
    height: 0;
    opacity: 0;


    &.show {
      height: unset;
      opacity: 1;
    }

    .icon-close {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      width: 32px;
      height: 32px;
      border-radius: 2px;
      background: $border_blue;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        filter: brightness(15);
      }
    }

    &__title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    &__txt {
      margin-top: 20px;
      font-size: 14px;
    }

    p:first-of-type {
      font-weight: bold;
    }
  }
}

.mantine-1bn98az:not(:disabled):hover {
  background-color: unset !important;
}
